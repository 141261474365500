import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/packages/website/src/components/DocsLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`React Ensemble is hosted on `}<a parentName="p" {...{
        "href": "https://npmjs.com"
      }}>{`npm`}</a>{` under the package name `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/react-ensemble"
      }}>{`react-ensemble`}</a>{`.`}</p>
    <p>{`You can add it to your project from the command line with `}<a parentName="p" {...{
        "href": "https://classic.yarnpkg.com/en/"
      }}>{`Yarn`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`yarn add react-ensemble
`}</code></pre>
    <p>{`Or with `}<a parentName="p" {...{
        "href": "https://npmjs.com"
      }}>{`npm`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npm i react-ensemble
`}</code></pre>
    <h2 {...{
      "id": "compatibility-with-react"
    }}>{`Compatibility with React`}</h2>
    <p>{`React Ensemble requires an installation of `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/react"
      }}>{`react`}</a>{` v16.8+. If you are starting a new React project with `}<a parentName="p" {...{
        "href": "https://create-react-app.dev/"
      }}>{`Create React App`}</a>{`, your `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` dependencies should look something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`"dependencies": {
  "react": "^16.13.1",
  "react-dom": "^16.13.1",
  "react-ensemble": "^1.1.0"
}
`}</code></pre>
    <p>{`Otherwise, be sure to add the missing package:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`yarn add react@latest
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      